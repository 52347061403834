import axios from 'axios';
import { config } from '../config';

const getRatings = async function (key, page, size) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/ratings/all?page=${page}&size=${size}`,
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export { getRatings };