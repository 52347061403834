import { useEffect, useState } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Reset from "../icons/Reset";
import Check from "../icons/Check";
import Cancel from "../icons/Cancel";
import Copy from "../icons/Copy";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getFaqs } from "../api/faqs";
import Loading from "../icons/Loading";
import Delete from "../icons/Delete";
import { useAddFaq, useDeleteFaq, useUpdateFaqDetails, } from "../hooks/useFaqs";
import Edit from "../icons/Edit";

export default function Faqs() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const {
		errorAddFaq, handleAddFaq, loadingAddFaq, removeAddFaqModal, question, setAnswer, setQuestion, 
		setRemoveAddFaqModal, successAddFaq, answer, handleAnswer, handleQuestion
	} = useAddFaq();

	const {
		errorUpdateFaqDetails, handleQuestion: handleQuestionEdit, handleAnswer: handleAnswerEdit, handleUpdateFaqDetails, loadingUpdateFaqDetails,
		question: questionEdit, answer: answerEdit, removeUpdateFaqDetailsModal, setQuestion: setQuestionEdit, setRemoveUpdateFaqDetailsModal,
		setUniqueId: EditUniqueIdDetails, successUpdateFaqDetails, setAnswer: setAnswerEdit
	} = useUpdateFaqDetails();

	const {
		errorDeleteFaq, handleDeleteFaq, loadingDeleteFaq, removeDeleteFaqModal, setUniqueId: DeleteUniqueId,
		setRemoveDeleteFaqModal, successDeleteFaq
	} = useDeleteFaq();

	const [allFaq, setAllFaq] = useState(null);
	const [errorFaq, setErrorFaq] = useState(null);
	const [loadingAllFaq, setLoadingAllFaq] = useState(false);

	const [size, setSize] = useState(50);
	const [page, setPage] = useState(1);

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); getAllFaqs(page, e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); getAllFaqs(parseInt(e.target.value), size); };

	async function previousFaq() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) getAllFaqs(page - 1, size);
	};

	async function nextFaq() {
		if (page < allFaq.data.pages) setPage(page + 1);
		if (page < allFaq.data.pages) getAllFaqs(page + 1, size);
	};

	async function getAllFaqs(_page, _size) {
		setLoadingAllFaq(true);
		const response = await getFaqs(cookie, (_page || page), (_size || size));
		setAllFaq(response.data);
		if (response.error) setErrorFaq(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllFaq(false);
	};

	useEffect(() => {
		if (allFaq === null) {
			getAllFaqs();
		}
	}, [allFaq]);

	const [loadingViewFaq, setLoadingViewFaq] = useState(false)
	const [errorViewFaq, setErrorViewFaq] = useState(null)
	const [viewFaq, setViewFaq] = useState(null)

	if (removeAddFaqModal) {
		const modalResponse = document.querySelector("#addFaqModal");
		modalResponse.setAttribute("display", false);
		getAllFaqs();
		setRemoveAddFaqModal(null);
	}
	if (removeUpdateFaqDetailsModal) {
		const modalResponse = document.querySelector("#editFaqModal");
		modalResponse.setAttribute("display", false);
		getAllFaqs();
		setRemoveUpdateFaqDetailsModal(null);
	}
	if (removeDeleteFaqModal) {
		const modalResponse = document.querySelector("#deleteFaqModal");
		modalResponse.setAttribute("display", false);
		getAllFaqs();
		setRemoveDeleteFaqModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allFaq ? allFaq.data.pages : 0).fill(0);

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Faqs</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View and edit all faqs</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="addFaqModal">
										<span className="xui-mr-half">Add Faq</span>
										<Plus width="15" height="15" />
									</button>
								</div>
							</div>
						</div>
						{
							loadingAllFaq ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allFaq && allFaq.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-150'>Question</th>
														<th className='xui-min-w-300'>Created At</th>
														<th className='xui-min-w-300'>Updated At</th>
														<th className='xui-min-w-150'>Actions</th>
													</tr>
												</thead>
												<tbody>
													{allFaq.data.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.question}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.createdAt.date} at {data.createdAt.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	<button title="Edit Faq" onClick={() => { EditUniqueIdDetails(data.unique_id); setViewFaq(data); setQuestionEdit(data.question); setAnswerEdit(data.answer); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="editFaqModal">
																		<Edit width="20" height="20" />
																	</button>
																	<button title="Delete Faq" onClick={() => { DeleteUniqueId(data.unique_id); }} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="deleteFaqModal">
																		<Delete width="16" height="16" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorFaq}</h3>
												</center>
											</div>
										</div>
								)
						}
						{/* {
							loadingAllFaq ?
								<Loading width="12" height="12" /> :
								(
									allFaq && allFaq.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option key={index + 1} value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allFaq ? allFaq.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousFaq}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextFaq}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						} */}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="deleteFaqModal" id="deleteFaqModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Delete Faq</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeleteFaq}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeleteFaq}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeleteFaq} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeleteFaq ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeleteFaq ? "" : "deleteFaqModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="addFaqModal" id="addFaqModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-800 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="addFaqModal">
						<Close width="24" height="24" />
					</div>
					<h1>Create new Faq</h1>
					<form className="xui-form" layout="2" onSubmit={handleAddFaq}>
						<div className="xui-form-box xui-mt-2">
							<label>Question</label>
							<input className="xui-font-sz-90" type="text" value={question} onChange={handleQuestion} required placeholder="Enter question"></input>
						</div>
						<div className="xui-form-box xui-mt-2">
							<label>Answer</label>
							<textarea type={"text"} maxLength={1000} placeholder={"Enter answer"} value={answer} onChange={handleAnswer}></textarea>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button disabled={loadingAddFaq} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Save Faq</span>
								{
									loadingAddFaq ?
										<Loading width="12" height="12" />
										: <Arrowright width="12" height="12" />
								}
							</button>
						</div>
					</form>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorAddFaq}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successAddFaq}</span></p>
				</div>
			</section>
			<section className='xui-modal' xui-modal="editFaqModal" id="editFaqModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-800 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="editFaqModal">
						<Close width="24" height="24" />
					</div>
					<h1>Edit Faq</h1>
					<form className="xui-form xui-mt-2" layout="2" onSubmit={(e) => e.preventDefault()}>
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<div className="xui-form-box xui-mt-2">
								<label>Question</label>
								<input className="xui-font-sz-90" type="text" value={questionEdit} onChange={handleQuestionEdit} required placeholder="Enter question"></input>
							</div>
							<div className="xui-form-box xui-mt-2">
								<label>Answer</label>
								<textarea type={"text"} maxLength={1000} placeholder={"Enter answer"} value={answerEdit} onChange={handleAnswerEdit}></textarea>
							</div>
							<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
								<button onClick={handleUpdateFaqDetails} disabled={loadingUpdateFaqDetails} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-85">
									<span className="xui-mr-half">Save Changes</span>
									{
										loadingUpdateFaqDetails ?
											<Loading width="12" height="12" />
											: <Check width="12" height="12" />
									}
								</button>
							</div>
							<p className="xui-font-sz-80 xui-my-1 xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorUpdateFaqDetails}</span></p>
							<p className="xui-font-sz-80 xui-my-1 xui-text-green"><span className="xui-font-w-bold psc-text-red">{successUpdateFaqDetails}</span></p>
						</div>
					</form>
				</div>
			</section>
		</>
	);

}
