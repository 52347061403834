import { useState } from "react";
import useCookie from "./useCookie";
import { config } from "../config";
import { acceptOrderDispute, denyOrderDispute, updateOrderPaid } from "../api/orders";

const useUpdateOrderPaid = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUpdateOrderPaid, setLoadingUpdateOrderPaid] = useState(false);
	const [removeUpdateOrderPaidModal, setRemoveUpdateOrderPaidModal] = useState(null);
	const [trackingNumber, setTrackingNumber] = useState(null);
	const [userUniqueId, setUserUniqueId] = useState(null);

	const [errorUpdateOrderPaid, setErrorUpdateOrderPaid] = useState(null);
	const [successUpdateOrderPaid, setSuccessUpdateOrderPaid] = useState(null);

	const handleUpdateOrderPaid = (e) => {
		e.preventDefault();

		if (!loadingUpdateOrderPaid) {
			if (!trackingNumber) {
				setErrorUpdateOrderPaid(null);
				setSuccessUpdateOrderPaid(null);
				setErrorUpdateOrderPaid("Unique ID is required");
				setTimeout(function () {
					setErrorUpdateOrderPaid(null);
				}, 2500)
			} else if (!userUniqueId) {
				setErrorUpdateOrderPaid("User Unique Id is required");
				setTimeout(function () {
					setErrorUpdateOrderPaid(null);
				}, 2500)
			} else {
				setLoadingUpdateOrderPaid(true);

				const updateOrderPaidRes = updateOrderPaid(cookie, {
					tracking_number: trackingNumber,
					user_unique_id: userUniqueId
				})

				updateOrderPaidRes.then(res => {
					setLoadingUpdateOrderPaid(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUpdateOrderPaid(error);
							setTimeout(function () {
								setErrorUpdateOrderPaid(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUpdateOrderPaid(error);
							setTimeout(function () {
								setErrorUpdateOrderPaid(null);
							}, 2000)
						}
					} else {
						setErrorUpdateOrderPaid(null);
						setSuccessUpdateOrderPaid(`Order paid successfully!`);

						setTimeout(function () {
							setSuccessUpdateOrderPaid(null);
							setRemoveUpdateOrderPaidModal(true);
							setTrackingNumber(null);
							setUserUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUpdateOrderPaid(false);
				})

			}
		}
	};

	return {
		cookie, loadingUpdateOrderPaid, removeUpdateOrderPaidModal, errorUpdateOrderPaid, successUpdateOrderPaid, handleUpdateOrderPaid,
		setRemoveUpdateOrderPaidModal, setTrackingNumber, setUserUniqueId,
	};
};

const useAcceptOrderDispute = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingAcceptOrderDispute, setLoadingAcceptOrderDispute] = useState(false);
	const [removeAcceptOrderDisputeModal, setRemoveAcceptOrderDisputeModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [userUniqueId, setUserUniqueId] = useState(null);

	const [errorAcceptOrderDispute, setErrorAcceptOrderDispute] = useState(null);
	const [successAcceptOrderDispute, setSuccessAcceptOrderDispute] = useState(null);

	const handleAcceptOrderDispute = (e) => {
		e.preventDefault();

		if (!loadingAcceptOrderDispute) {
			if (!uniqueId) {
				setErrorAcceptOrderDispute(null);
				setSuccessAcceptOrderDispute(null);
				setErrorAcceptOrderDispute("Unique ID is required");
				setTimeout(function () {
					setErrorAcceptOrderDispute(null);
				}, 2500)
			} else if (!userUniqueId) {
				setErrorAcceptOrderDispute("User Unique Id is required");
				setTimeout(function () {
					setErrorAcceptOrderDispute(null);
				}, 2500)
			} else {
				setLoadingAcceptOrderDispute(true);

				const acceptOrderDisputeRes = acceptOrderDispute(cookie, {
					unique_id: uniqueId,
					user_unique_id: userUniqueId
				})

				acceptOrderDisputeRes.then(res => {
					setLoadingAcceptOrderDispute(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorAcceptOrderDispute(error);
							setTimeout(function () {
								setErrorAcceptOrderDispute(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorAcceptOrderDispute(error);
							setTimeout(function () {
								setErrorAcceptOrderDispute(null);
							}, 2000)
						}
					} else {
						setErrorAcceptOrderDispute(null);
						setSuccessAcceptOrderDispute(`Order dispute accepted successfully!`);

						setTimeout(function () {
							setSuccessAcceptOrderDispute(null);
							setRemoveAcceptOrderDisputeModal(true);
							setUniqueId(null);
							setUserUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingAcceptOrderDispute(false);
				})

			}
		}
	};

	return {
		cookie, loadingAcceptOrderDispute, removeAcceptOrderDisputeModal, errorAcceptOrderDispute, successAcceptOrderDispute, handleAcceptOrderDispute,
		setRemoveAcceptOrderDisputeModal, setUniqueId, setUserUniqueId,
	};
};

const useDenyOrderDispute = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDenyOrderDispute, setLoadingDenyOrderDispute] = useState(false);
	const [removeDenyOrderDisputeModal, setRemoveDenyOrderDisputeModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [userUniqueId, setUserUniqueId] = useState(null);
	const [feedback, setFeedback] = useState(null);

	const [errorDenyOrderDispute, setErrorDenyOrderDispute] = useState(null);
	const [successDenyOrderDispute, setSuccessDenyOrderDispute] = useState(null);

	const handleFeedback = (e) => { e.preventDefault(); setFeedback(e.target.value); };

	const handleDenyOrderDispute = (e) => {
		e.preventDefault();

		if (!loadingDenyOrderDispute) {
			if (!uniqueId) {
				setErrorDenyOrderDispute(null);
				setSuccessDenyOrderDispute(null);
				setErrorDenyOrderDispute("Unique ID is required");
				setTimeout(function () {
					setErrorDenyOrderDispute(null);
				}, 2500)
			} else if (!userUniqueId) {
				setErrorDenyOrderDispute("User Unique Id is required");
				setTimeout(function () {
					setErrorDenyOrderDispute(null);
				}, 2500)
			} else if (!feedback) {
				setErrorDenyOrderDispute("Feedback is required");
				setTimeout(function () {
					setErrorDenyOrderDispute(null);
				}, 2500)
			} else if (feedback.length < 3) {
				setErrorDenyOrderDispute("Feedback minimum characters - 3");
				setTimeout(function () {
					setErrorDenyOrderDispute(null);
				}, 2500)
			} else if (feedback.length > 1000) {
				setErrorDenyOrderDispute("Feedback maximum characters - 1000");
				setTimeout(function () {
					setErrorDenyOrderDispute(null);
				}, 2500)
			} else {
				setLoadingDenyOrderDispute(true);

				const denyOrderDisputeRes = denyOrderDispute(cookie, {
					unique_id: uniqueId,
					user_unique_id: userUniqueId,
					feedback: feedback
				})

				denyOrderDisputeRes.then(res => {
					setLoadingDenyOrderDispute(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDenyOrderDispute(error);
							setTimeout(function () {
								setErrorDenyOrderDispute(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDenyOrderDispute(error);
							setTimeout(function () {
								setErrorDenyOrderDispute(null);
							}, 2000)
						}
					} else {
						setErrorDenyOrderDispute(null);
						setSuccessDenyOrderDispute(`Order dispute denied successfully!`);

						setTimeout(function () {
							setSuccessDenyOrderDispute(null);
							setRemoveDenyOrderDisputeModal(true);
							setUniqueId(null);
							setUserUniqueId(null);
							setFeedback(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDenyOrderDispute(false);
				})

			}
		}
	};

	return {
		cookie, loadingDenyOrderDispute, removeDenyOrderDisputeModal, errorDenyOrderDispute, successDenyOrderDispute, handleDenyOrderDispute,
		setRemoveDenyOrderDisputeModal, setUniqueId, setUserUniqueId, setFeedback, feedback, handleFeedback
	};
};

export { useUpdateOrderPaid, useAcceptOrderDispute, useDenyOrderDispute };
