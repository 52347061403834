import { useEffect, useState } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Reset from "../icons/Reset";
import Check from "../icons/Check";
import Cancel from "../icons/Cancel";
import Copy from "../icons/Copy";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getProducts, getProductsViaUser, getProduct, getUnpublishedProducts } from "../api/products";
import { usePublishProduct } from "../hooks/useProducts";
import Loading from "../icons/Loading";
import Filter from "../icons/Filter";
import EyeOpen from "../icons/EyeOpen";
import Search from "../icons/Search";
import EyeOpenAlt from "../icons/EyeOpenAlt";

export default function Products() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const [currentFunction, setCurrentFunction] = useState("getAllProducts");

	const {
		errorPublishProduct, handlePublishProduct, loadingPublishProduct, removePublishProductModal,
		setRemovePublishProductModal, setUniqueId: PublishProductSetUniqueId, setUserUniqueId: PublishProductSetUserUniqueId,
		successPublishProduct, uniqueId: PublishProductUniqueId, userUniqueId: PublishProductUserUniqueId
	} = usePublishProduct();

	const [filterByUserUniqueId, setFilterByUserUniqueId] = useState("");
	const [removeUserFilterModal, setRemoveUserFilterModal] = useState(null);

	const showPreview = function (file) {
		const preview = file;

		window.open(preview, "_blank");
	};

	const [allProducts, setAllProducts] = useState(null);
	const [errorProducts, setErrorProducts] = useState(null);
	const [loadingAllProducts, setLoadingAllProducts] = useState(false);

	const [size, setSize] = useState(50);
	const [page, setPage] = useState(1);

	const handleFilterByUserUniqueId = (e) => { e.preventDefault(); setFilterByUserUniqueId(e.target.value); };

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); productsBySize(e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); productsByPage(parseInt(e.target.value), size); };

	const resetUserFilterParameters = () => {
		setFilterByUserUniqueId("");
		setCurrentFunction("getAllProducts");
	};

	const continueUserFilterByUser = (e) => {
		e.preventDefault();

		setPage(1);
		setCurrentFunction("getAllUserProducts");
		getAllUserProducts(filterByUserUniqueId, page, size);
		setRemoveUserFilterModal(true);
	};

	async function callLastProductFunction() {
		switch (currentFunction) {
			case "getAllProducts":
				getAllProducts(page, size);
				break;
			case "getAllUserProducts":
				getAllUserProducts(filterByUserUniqueId, page, size);
				break;
			// default:
			// 	getAllProducts(page, size);
		}
	};

	async function productsBySize(size) {
		switch (currentFunction) {
			case "getAllProducts":
				getAllProducts(page, size);
				break;
			case "getAllUserProducts":
				getAllUserProducts(filterByUserUniqueId, page, size);
				break;
			default:
				getAllProducts(page, size);
		}
	};

	async function productsByPage(page) {
		switch (currentFunction) {
			case "getAllProducts":
				getAllProducts(page, size);
				break;
			case "getAllUserProducts":
				getAllUserProducts(filterByUserUniqueId, page, size);
				break;
			default:
				getAllProducts(page, size);
		}
	};

	async function previousProducts() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) {
			switch (currentFunction) {
				case "getAllProducts":
					getAllProducts(page - 1, size);
					break;
				case "getAllUserProducts":
					getAllUserProducts(filterByUserUniqueId, page - 1, size);
					break;
				default:
					getAllProducts(page - 1, size);
			}
		};
	};

	async function nextProducts() {
		if (page < allProducts.data.pages) setPage(page + 1);
		if (page < allProducts.data.pages) {
			switch (currentFunction) {
				case "getAllProducts":
					getAllProducts(page + 1, size);
					break;
				case "getAllUserProducts":
					getAllUserProducts(filterByUserUniqueId, page + 1, size);
					break;
				default:
					getAllProducts(page + 1, size);
			}
		};
	};

	async function getAllProducts(_page, _size) {
		setLoadingAllProducts(true);
		const response = await getProducts(cookie, (_page || page), (_size || size));
		setAllProducts(response.data);
		if (response.error) setErrorProducts(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllProducts(false);
	};

	async function getAllUnpublishedProducts(_page, _size) {
		setLoadingAllProducts(true);
		const response = await getUnpublishedProducts(cookie, (_page || page), (_size || size));
		setAllProducts(response.data);
		if (response.error) setErrorProducts(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllProducts(false);
	};

	async function getAllUserProducts(user_unique_id, _page, _size) {
		setLoadingAllProducts(true);
		const response = await getProductsViaUser(cookie, (_page || page), (_size || size), ({ user_unique_id: user_unique_id }));
		setAllProducts(response.data);
		if (response.error) setErrorProducts(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllProducts(false);
	};

	useEffect(() => {
		if (allProducts === null) {
			callLastProductFunction();
		}
	}, [allProducts]);

	const [loadingViewProduct, setLoadingViewProduct] = useState(false)
	const [errorViewProduct, setErrorViewProduct] = useState(null)
	const [viewProduct, setViewProduct] = useState(null)

	async function getAProduct(unique_id, user_unique_id) {
		setLoadingViewProduct(true)
		const response = await getProduct(cookie, { unique_id, user_unique_id });
		if (!response.err) {
			setViewProduct(response.data);
			setSelectedImage(response.data.data.data.product_images_data ? response.data.data.data.product_images_data[0].image : "");
			setThumbnailImages(response.data.data.data.product_images_data ? response.data.data.data.product_images_data.map(image => image.image) : []);
		} else { setErrorViewProduct(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewProduct(false)
	};

	if (removeUserFilterModal) {
		const modalResponse = document.querySelector("#filterByUser");
		modalResponse.setAttribute("display", false);
		callLastProductFunction();
		setRemoveUserFilterModal(null);
	}

	if (removePublishProductModal) {
		const modalResponse = document.querySelector("#publishProductModal");
		modalResponse.setAttribute("display", false);
		const modalResponse2 = document.querySelector("#viewProductModal");
		modalResponse2.setAttribute("display", false);
		getAProduct(PublishProductUniqueId, PublishProductUserUniqueId);
		callLastProductFunction();
		setRemovePublishProductModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allProducts ? allProducts.data.pages : 0).fill(0);

	const getObjectValues = (obj) => {
		let values = [];
		Object.keys(obj).some((key) => {
			values.push(<span id={obj[key]}><b>{key}</b> : {obj[key].length > 300 ? obj[key].slice(0, 200) + " ..." : obj[key]}<br></br></span>)
		});
		return values;
	};

	const [selectedImage, setSelectedImage] = useState("");
	const [thumbnailImages, setThumbnailImages] = useState([]);
	const handleThumbnailClick = (image) => {
		setSelectedImage(image);
	};

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All Products</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View and filter all products</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button onClick={getAllUnpublishedProducts} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80">
										<span className="xui-mr-half">Unpublished Products</span>
										<Filter width="15" height="15" />
									</button>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-80" xui-modal-open="filterByUser">
										<span className="xui-mr-half">Search</span>
										<Search width="15" height="15" />
									</button>
								</div>
							</div>
						</div>
						{
							loadingAllProducts ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allProducts && allProducts.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-150'>Unique ID</th>
														<th className='xui-min-w-150'>User</th>
														<th className='xui-min-w-150'>Category</th>
														<th className='xui-min-w-200'>Product</th>
														<th className='xui-min-w-200'>Product Price</th>
														<th className='xui-min-w-150'>Product Image</th>
														<th className='xui-min-w-150'>Views</th>
														<th className='xui-min-w-150'>Favorites</th>
														<th className='xui-min-w-150'>Ratings (Good/Bad)</th>
														<th className='xui-min-w-150'>Status</th>
														<th className='xui-min-w-300'>Created At</th>
														<th className='xui-min-w-300'>Updated At</th>
														<th className='xui-min-w-100'>Action</th>
													</tr>
												</thead>
												<tbody>
													{allProducts.data.rows.sort((a, b) => new Date(a.updatedAt.date + " " + a.updatedAt.time).getTime() < new Date(b.updatedAt.date + " " + b.updatedAt.time).getTime() ? 1 : -1).map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>#{data.unique_id}</span>
																	<span title="Copy Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.unique_id); setTextCopied(data.unique_id); }}>
																		{copiedText && textCopied === data.unique_id ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>{(data.user_data ? data.user_data.firstname + (data.user_data.middlename ? " " + data.user_data.middlename + " " : " ") + data.user_data.lastname : allProducts.data.user_data.firstname + (allProducts.data.user_data.middlename ? " " + allProducts.data.user_data.middlename + " " : " ") + allProducts.data.user_data.lastname)}</span>
																	<span title="Copy Unique Id" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.user_data ? data.user_data.user_unique_id : allProducts.data.user_data.user_unique_id); setTextCopied(data.user_data ? data.user_data.user_unique_id : allProducts.data.user_data.user_unique_id); }}>
																		{copiedText && textCopied === (data.user_data ? data.user_data.user_unique_id : allProducts.data.user_data.user_unique_id) ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.category_data.name}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.name} ({data.remaining}/{data.quantity})</span>
															</td>
															<td className='xui-opacity-5'>
																<span><b>NGN {data.sales_price ? <>{data.sales_price.toLocaleString()} <s> NGN {data.price.toLocaleString()}</s> </> : data.price.toLocaleString()}</b></span>
															</td>
															<td className=''>
																{
																	data.product_images_data === null || !data.product_images_data ?
																		<span>No image</span> :
																		<div className='xui-d-inline-flex xui-flex-ai-center'>
																			<img className="xui-img-50" src={data.product_images_data[0].image} alt="Category Image" />
																			<span title="Copy Image Link" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.product_images_data[0].image); setTextCopied(data.product_images_data[0].image); }}>
																				{copiedText && textCopied === data.product_images_data[0].image ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																			</span>
																			<span title="View File" className="xui-cursor-pointer xui-mx-1" onClick={() => { showPreview(data.product_images_data[0].image); }}>
																				<EyeOpen width="16" height="16" />
																			</span>
																		</div>
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.views.toLocaleString()}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.favorites.toLocaleString()}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.good_rating.toLocaleString()} / {data.bad_rating.toLocaleString()}</span>
															</td>
															<td className=''>
																{
																	data.status === 1 ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> : ""
																}
																{
																	data.status === 2 ?
																		<span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>Unpublished</span> : ""
																}
																{
																	data.status === 0 ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span> : ""
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.createdAt.date} at {data.createdAt.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	<button title="View Product Full Details"
																		onClick={() => {
																			getAProduct(data.unique_id, data.user_data.user_unique_id);
																		}} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewProductModal">
																		<EyeOpenAlt width="20" height="20" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorProducts}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllProducts ?
								<Loading width="12" height="12" /> :
								(
									allProducts && allProducts.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option key={index + 1} value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allProducts ? allProducts.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousProducts}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextProducts}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="filterByUser" id="filterByUser">
				<div className='xui-modal-content xui-max-h-700 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" onClick={() => resetUserFilterParameters()} xui-modal-close="filterByUser">
						<Close width="24" height="24" />
					</div>
					<h1>Filter Products By User</h1>
					<form className="xui-form" onSubmit={continueUserFilterByUser}>
						<div className="xui-form-box">
							<label>User Unique Id</label>
							<input className="xui-font-sz-90" type="text" value={filterByUserUniqueId} onChange={handleFilterByUserUniqueId} required placeholder="Enter/Paste User UniqueId"></input>
						</div>
						<div className="xui-form-box xui-d-flex xui-flex-jc-flex-end">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-70">
								<Filter width="16" height="16" />
								<span className="xui-ml-half">Filter</span>
							</button>
						</div>
					</form>
				</div>
			</section>
			<section className='xui-modal' xui-modal="viewProductModal" id="viewProductModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="viewProductModal">
						<Close width="24" height="24" />
					</div>
					{
						loadingViewProduct ?
							<center>
								<Loading width="12" height="12" />
							</center> : (
								viewProduct && viewProduct.success ?
									<>
										<center>
											<h1>Product Details</h1>
											<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">
												<div className='xui-d-inline-flex xui-flex-ai-center'>
													<span>#{viewProduct.data.data.unique_id}</span>
													<span title="Copy Product ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(viewProduct.data.data.unique_id); setTextCopied(viewProduct.data.data.unique_id); }}>
														{copiedText && textCopied === viewProduct.data.data.unique_id ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
													</span>
												</div>
											</p>
										</center>
										<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
											<div className="xui-w-200 xui-h-200 xui-m-1-half xui-mb-6">
												{
													viewProduct.data.user_data.photo === null || !viewProduct.data.user_data.photo ?
														<center>No image</center> :
														<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={viewProduct.data.user_data.photo} alt={viewProduct.data.user_data.firstname + (viewProduct.data.user_data.middlename ? " " + viewProduct.data.user_data.middlename + " " : " ") + viewProduct.data.user_data.lastname + " Profile Image"} />
												}
												<div className="xui-mb-4">
												</div>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">Product Images Below</p>
												<center>
													<p className="xui-opacity-4 xui-font-sz-200 xui-m-half">⬇️</p>
												</center>
											</div>
											<div className="xui-m-1-half xui-lg-ml--10 xui-md-ml--7">
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">User -</span> {viewProduct.data.user_data.firstname + (viewProduct.data.user_data.middlename ? " " + viewProduct.data.user_data.middlename + " " : " ") + viewProduct.data.user_data.lastname}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Category -</span> {viewProduct.data.data.category_data.name}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Name -</span> {viewProduct.data.data.name} ({viewProduct.data.data.stripped})</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Price -</span> NGN {viewProduct.data.data.sales_price ? <>{viewProduct.data.data.sales_price.toLocaleString()} <s> NGN {viewProduct.data.data.price.toLocaleString()}</s> </> : viewProduct.data.data.price.toLocaleString()}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Remaining / Quantity (Max Quantity) -</span> {viewProduct.data.data.remaining.toLocaleString()} / {viewProduct.data.data.quantity.toLocaleString()} ({viewProduct.data.data.max_quantity.toLocaleString()})</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">Specifications: </p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">
													<span className="xui-font-w-bold">
														{
															getObjectValues(viewProduct.data.data.specifications).map(val => {
																return val;
															})
														}
													</span>
												</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Views -</span> {viewProduct.data.data.views.toLocaleString()}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Favorites -</span> {viewProduct.data.data.favorites.toLocaleString()}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Good Ratings -</span> {viewProduct.data.data.good_rating.toLocaleString()}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Bad Ratings -</span> {viewProduct.data.data.bad_rating.toLocaleString()}</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">Description: </p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">{viewProduct.data.data.description}</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Status - </span> 
													{
														viewProduct.data.data.status === 1 ?
															<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> : ""
													}
													{
														viewProduct.data.data.status === 2 ?
															<span className='xui-badge xui-badge-warning xui-font-sz-80 xui-bdr-rad-half'>Unpublished</span> : ""
													}
													{
														viewProduct.data.data.status === 0 ?
															<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span> : ""
													}
												</p>
											</div>
										</div>
										<center>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewProduct.data.data.createdAt.fulldate} | Last Updated - {viewProduct.data.data.updatedAt.fulldate}</p>
											{
												viewProduct.data.data.status === 2 ?
													<div className="xui-m-2">
														<button title="Publish User Product" onClick={() => { PublishProductSetUniqueId(viewProduct.data.data.unique_id); PublishProductSetUserUniqueId(viewProduct.data.data.user_unique_id); }} className="xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="publishProductModal">
															<Check width="16" height="16" />
														</button>
													</div> : null
											}
											{
												viewProduct.data.data.status === 1 ?
													<div className="xui-m-2">
														<button title="Unpublish User Product" onClick={() => { PublishProductSetUniqueId(viewProduct.data.data.unique_id); PublishProductSetUserUniqueId(viewProduct.data.data.user_unique_id); }} className="xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="publishProductModal">
															<Close width="16" height="16" />
														</button>
													</div> : null
											}
										</center>
										{
											viewProduct.data.data.product_images_data === null || !viewProduct.data.data.product_images_data ?
												<center>No image</center> :
												<>
													<div className='xui-d-grid xui-grid-col-1 xui-lg-grid-col-3 xui-md-grid-col-3 xui-grid-gap-1 xui-lg-grid-gap-2 xui-mb-2'>
														{
															thumbnailImages.map((image, index) => (
																<div>
																	<img key={index} className={`thumbnail-image xui-w-fluid-100 xui-lg-h-200 xui-h-100 ${selectedImage === image ? 'selected' : ''}`} src={image} xui-img-src={image} alt={viewProduct.data.data.name + ` Image ${index + 1}`} onClick={() => handleThumbnailClick(image)} />
																	<p className="xui-opacity-4 xui-font-sz-100 xui-m-half xui-cursor-pointer xui-text-dc-underline" onClick={() => {
																		showPreview(image);
																	}}><span className="xui-font-w-bold">Click to view</span></p>
																</div>
															))
														}
													</div>
												</>
										}
									</> :
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewProduct}</h3>
											</center>
										</div>
									</div>
							)
					}
				</div>
			</section>
			<section className='xui-modal' xui-modal="publishProductModal" id="publishProductModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Publish / Unpublish User Product</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorPublishProduct}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successPublishProduct}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handlePublishProduct} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingPublishProduct ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingPublishProduct ? "" : "publishProductModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);

};