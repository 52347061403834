import axios from 'axios';
import { config } from '../config';

const getProducts = async function (key, page, size) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/products/all?page=${page}&size=${size}`,
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUnpublishedProducts = async function (key, page, size) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/products/unpublished?page=${page}&size=${size}`,
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getProductsViaUser = async function (key, page, size, payload) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/products/via/user?page=${page}&size=${size}&user_unique_id=${payload.user_unique_id}`,
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getProduct = async function (key, payload) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/product/via/user?unique_id=${payload.unique_id}&user_unique_id=${payload.user_unique_id}`,
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const publishProduct = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/product/publish`,
			{
				...payload
			},
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export {
	getProducts, getUnpublishedProducts, getProductsViaUser, getProduct, publishProduct
};