import { useEffect, useState } from "react";
import SuccessTick from "../assets/images/success-tick.png";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Screen from '../components/Screen';
import Arrowright from '../icons/Arrowright';
import Arrowleft from '../icons/Arrowleft';
import Close from "../icons/Close";
import Plus from "../icons/Plus";
import Reset from "../icons/Reset";
import Check from "../icons/Check";
import Cancel from "../icons/Cancel";
import Copy from "../icons/Copy";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { getKycs, getKycsViaVerification, getKycsViaType, getUserKyc } from "../api/kyc";
import { useVerifyKyc, useDeclineKyc } from "../hooks/useKyc";
import Loading from "../icons/Loading";
import Filter from "../icons/Filter";
import EyeOpen from "../icons/EyeOpen";
import Search from "../icons/Search";
import EyeOpenAlt from "../icons/EyeOpenAlt";

export default function Kycs() {
	const { cookie, forceLogout } = useCookie(config.key, "");
	const [copiedText, setCopiedText] = useState(false);
	const [textCopied, setTextCopied] = useState(null);

	const [currentFunction, setCurrentFunction] = useState("getAllKycs");

	const {
		errorVerifyKyc, handleVerifyKyc, loadingVerifyKyc, removeVerifyKycModal,
		setRemoveVerifyKycModal, setUniqueId: VerifyKycSetUniqueId, setUserUniqueId: VerifyKycSetUserUniqueId,
		successVerifyKyc
	} = useVerifyKyc();

	const {
		errorDeclineKyc, handleDeclineKyc, loadingDeclineKyc, removeDeclineKycModal,
		setRemoveDeclineKycModal, setUniqueId: DeclineKycSetUniqueId, setUserUniqueId: DeclineKycSetUserUniqueId,
		successDeclineKyc, message, handleMessage, setMessage
	} = useDeclineKyc();

	const [filterViaVerification, setFilterViaVerification] = useState("");
	
	const [filterViaType, setFilterViaType] = useState("");

	const showPreview = function (file) {
		const preview = file;

		window.open(preview, "_blank");
	};

	const [allKycs, setAllKycs] = useState(null);
	const [errorKycs, setErrorKycs] = useState(null);
	const [loadingAllKycs, setLoadingAllKycs] = useState(false);

	const [size, setSize] = useState(50);
	const [page, setPage] = useState(1);

	const handleFilterViaVerification = (e) => { e.preventDefault(); setFilterViaVerification(e.target.value); if (e.target.value === "null") resetVerificationFilterParameters(); else continueFilterViaVerification(e); };
	const handleFilterViaType = (e) => { e.preventDefault(); setFilterViaType(e.target.value); if (e.target.value === "null") resetTypeFilterParameters(); else continueFilterViaType(e); };

	const handleSize = (e) => { e.preventDefault(); setSize(e.target.value); setPage(1); kycsBySize(e.target.value); };
	const handlePage = (e) => { e.preventDefault(); setPage(parseInt(e.target.value)); kycsByPage(parseInt(e.target.value), size); };

	const resetVerificationFilterParameters = () => {
		setFilterViaVerification("");
		setCurrentFunction("getAllKycs");
		getAllKycs();
	};

	const resetTypeFilterParameters = () => {
		setFilterViaType("");
		setCurrentFunction("getAllKycs");
		getAllKycs();
	};
	
	const continueFilterViaVerification = (e) => {
		e.preventDefault();
		
		setPage(1);
		setCurrentFunction("getAllKycsViaVerification");
		getAllKycsViaVerification(e.target.value, page, size);
	};

	const continueFilterViaType = (e) => {
		e.preventDefault();

		setPage(1);
		setCurrentFunction("getAllKycsViaType");
		getAllKycsViaType(e.target.value, page, size);
	};

	async function callLastKycFunction() {
		switch (currentFunction) {
			case "getAllKycs":
				getAllKycs(page, size);
				break;
			case "getAllKycsViaVerification":
				getAllKycsViaVerification(filterViaVerification, page, size);
				break;
			case "getAllKycsViaType":
				getAllKycsViaType(filterViaType, page, size);
				break;
			// default:
			// 	getAllKycs(page, size);
		}
	};

	async function kycsBySize(size) {
		switch (currentFunction) {
			case "getAllKycs":
				getAllKycs(page, size);
				break;
			case "getAllKycsViaVerification":
				getAllKycsViaVerification(filterViaVerification, page, size);
				break;
			case "getAllKycsViaType":
				getAllKycsViaType(filterViaType, page, size);
				break;
			default:
				getAllKycs(page, size);
		}
	};

	async function kycsByPage(page) {
		switch (currentFunction) {
			case "getAllKycs":
				getAllKycs(page, size);
				break;
			case "getAllKycsViaVerification":
				getAllKycsViaVerification(filterViaVerification, page, size);
				break;
			case "getAllKycsViaType":
				getAllKycsViaType(filterViaType, page, size);
				break;
			default:
				getAllKycs(page, size);
		}
	};

	async function previousKycs() {
		if (page !== 1) setPage(page - 1);
		if (page !== 1) {
			switch (currentFunction) {
				case "getAllKycs":
					getAllKycs(page - 1, size);
					break;
				case "getAllKycsViaVerification":
					getAllKycsViaVerification(filterViaVerification, page - 1, size);
					break;
				case "getAllKycsViaType":
					getAllKycsViaType(filterViaType, page - 1, size);
					break;
				default:
					getAllKycs(page - 1, size);
			}
		};
	};

	async function nextKycs() {
		if (page < allKycs.data.pages) setPage(page + 1);
		if (page < allKycs.data.pages) {
			switch (currentFunction) {
				case "getAllKycs":
					getAllKycs(page + 1, size);
					break;
				case "getAllKycsViaVerification":
					getAllKycsViaVerification(filterViaVerification, page + 1, size);
					break;
				case "getAllKycsViaType":
					getAllKycsViaType(filterViaType, page + 1, size);
					break;
				default:
					getAllKycs(page + 1, size);
			}
		};
	};

	async function getAllKycs(_page, _size) {
		setLoadingAllKycs(true);
		const response = await getKycs(cookie, (_page || page), (_size || size));
		setAllKycs(response.data);
		if (response.error) setErrorKycs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllKycs(false);
	};

	async function getAllKycsViaVerification(verified, _page, _size) {
		setLoadingAllKycs(true);
		const response = await getKycsViaVerification(cookie, (_page || page), (_size || size), ({ verified: verified }));
		setAllKycs(response.data);
		if (response.error) setErrorKycs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllKycs(false);
	};

	async function getAllKycsViaType(proof_type, _page, _size) {
		setLoadingAllKycs(true);
		const response = await getKycsViaType(cookie, (_page || page), (_size || size), ({ proof_type: proof_type }));
		setAllKycs(response.data);
		console.log(response)
		if (response.error) setErrorKycs(response.response_code !== 422 ? response.error.response.data.message : response.error.response.data.data[0].msg);
		setLoadingAllKycs(false);
	};

	useEffect(() => {
		if (allKycs === null) {
			callLastKycFunction();
		}
	}, [allKycs]);

	const [loadingViewKyc, setLoadingViewKyc] = useState(false)
	const [errorViewKyc, setErrorViewKyc] = useState(null)
	const [viewKyc, setViewKyc] = useState(null)

	async function getAKyc(user_unique_id) {
		setLoadingViewKyc(true)
		const response = await getUserKyc(cookie, { user_unique_id });
		if (!response.err) {
			setViewKyc(response.data);
		} else { setErrorViewKyc(response.response_code === 422 ? response.error.response.data.data[0].msg : response.error.response.data.message) }
		setLoadingViewKyc(false)
	};

	if (removeVerifyKycModal) {
		const modalResponse = document.querySelector("#verifyKycModal");
		modalResponse.setAttribute("display", false);
		callLastKycFunction();
		setRemoveVerifyKycModal(null);
	}
	if (removeDeclineKycModal) {
		const modalResponse = document.querySelector("#declineKycModal");
		modalResponse.setAttribute("display", false);
		callLastKycFunction();
		setRemoveDeclineKycModal(null);
	}

	const copySomeText = (text) => {
		navigator.clipboard.writeText(text);
	};

	const copyText = (text) => {
		copySomeText(text);
		setCopiedText(true);
		setTimeout(function () {
			setCopiedText(false);
		}, 2000)
	};

	const pageSelectArray = new Array(allKycs ? allKycs.data.pages : 0).fill(0);

	return (
		<>
			<Screen aside="false" navbar="false">
				<Content>
					<Navbar placeholder="Search something..." makeHidden={true} />
					<section className=''>
						<div className='xui-d-flex xui-flex-ai-center xui-flex-jc-space-between xui-py-1 psc-section-header'>
							<div className="xui-mb-1">
								<h1 className='xui-font-sz-110 xui-font-w-normal'>All KYCs</h1>
								<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">View and filter all kycs</p>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={filterViaType} onChange={handleFilterViaType} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												!filterViaType ?
													<option selected value={null}>Filter By Proof Type</option> :
													<option value={"null"}>Select to Reset</option>
											}
											<option value={"NIMC"}>NIMC</option>
											<option value={"NINS"}>NINS</option>
											<option value={"Selfie"}>Selfie</option>
										</select>
									</div>
								</div>
							</div>
							<div className="xui-mb-1">
								<div className='xui-d-inline-flex'>
									<div className='xui-d-inline-flex xui-flex-ai-center xui-ml-1-half'>
										<Filter width="16" height="16" />
										<select value={filterViaVerification} onChange={handleFilterViaVerification} className='psc-select-rows-per-page xui-font-w-normal xui-font-sz-80 xui-ml-half'>
											{
												!filterViaVerification ?
													<option selected value={null}>Filter By Verification</option> :
													<option value={"null"}>Select to Reset</option>
											}
											<option value={"true"}>TRUE</option>
											<option value={"false"}>FALSE</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						{
							loadingAllKycs ?
								<center className='xui-font-sz-110 xui-py-3'><Loading width="12" height="12" /></center> :
								(
									allKycs && allKycs.success ?
										<div className='xui-table-responsive'>
											<table className='xui-table xui-font-sz-90'>
												<thead>
													<tr className='xui-text-left xui-opacity-6'>
														<th className='xui-w-30'>S/N</th>
														<th className='xui-min-w-150'>Unique ID</th>
														<th className='xui-min-w-150'>KYC Type</th>
														<th className='xui-min-w-150'>Number</th>
														<th className='xui-min-w-100'>Verification</th>
														<th className='xui-min-w-100'>Status</th>
														<th className='xui-min-w-300'>Created At</th>
														<th className='xui-min-w-300'>Updated At</th>
														<th className='xui-min-w-100'>Action</th>
													</tr>
												</thead>
												<tbody>
													{allKycs.data.rows.map((data, i) => (
														<tr className='' key={i}>
															<td className='xui-opacity-5'>
																<span>{i + 1}</span>
															</td>
															<td className='xui-opacity-5'>
																<div className='xui-d-inline-flex xui-flex-ai-center'>
																	<span>#{data.unique_id}</span>
																	<span title="Copy Unique ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(data.unique_id); setTextCopied(data.unique_id); }}>
																		{copiedText && textCopied === data.unique_id ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
																	</span>
																</div>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.proof_type}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.number}</span>
															</td>
															<td className=''>
																{
																	data.verified ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Yes</span> :
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No</span> 
																}
															</td>
															<td className=''>
																{
																	data.status === 1 ?
																		<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Active</span> : ""
																}
																{
																	data.status === 0 ?
																		<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>Inactive</span> : ""
																}
															</td>
															<td className='xui-opacity-5'>
																<span>{data.createdAt.date} at {data.createdAt.time}</span>
															</td>
															<td className='xui-opacity-5'>
																<span>{data.updatedAt.date} at {data.updatedAt.time}</span>
															</td>
															<td className=''>
																<div className="xui-d-flex xui-grid-gap-1">
																	<button title="View Kyc Full Details"
																		onClick={() => {
																			getAKyc(data.user_unique_id);
																		}} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-blue xui-bdr-rad-half xui-font-sz-50" xui-modal-open="viewKycModal">
																		<EyeOpenAlt width="20" height="20" />
																	</button>
																</div>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div> :
										<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
											<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
												<center className="xui-text-red">
													<Close width="100" height="100" />
													<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorKycs}</h3>
												</center>
											</div>
										</div>
								)
						}
						{
							loadingAllKycs ?
								<Loading width="12" height="12" /> :
								(
									allKycs && allKycs.success ?
										<div className='xui-d-flex xui-flex-jc-flex-end xui-py-1 xui-font-sz-85 xui-opacity-5 xui-mt-1'>
											<div className='xui-d-inline-flex xui-flex-ai-center'>
												<span>Rows per page:</span>
												<select value={size} onChange={handleSize} className='psc-select-rows-per-page xui-ml-half'>
													<option value={20}>20</option>
													<option value={50}>50</option>
													<option value={100}>100</option>
													<option value={500}>500</option>
													<option value={1000}>1000</option>
												</select>
											</div>
											<div className='xui-mx-1 xui-lg-mx-2'>
												<span><span className='xui-font-w-bold'><select value={page} onChange={handlePage} className='psc-select-rows-per-page xui-ml-half'>
													{
														pageSelectArray.map((value, index) => {
															return (
																<option key={index + 1} value={index + 1}>{index + 1}</option>
															)
														})
													}
												</select></span> of {allKycs ? allKycs.data.pages : "..."}</span>
											</div>
											<div className='xui-d-inline-flex xui-flex-ai-center xui-mx-1'>
												<div className='xui-mr-half xui-cursor-pointer' title="Previous" onClick={previousKycs}>
													<Arrowleft width="18" height="18" />
												</div>
												<div className='xui-ml-half xui-cursor-pointer' title="Next" onClick={nextKycs}>
													<Arrowright width="18" height="18" />
												</div>
											</div>
										</div> :
										""
								)
						}
					</section>
				</Content>
			</Screen>
			<section className='xui-modal' xui-modal="viewKycModal" id="viewKycModal">
				<div className='xui-modal-content xui-max-h-700 xui-max-w-1000 xui-overflow-auto xui-pos-relative'>
					<div className="xui-w-40 xui-h-40 xui-bdr-rad-circle xui-d-flex xui-flex-ai-center xui-flex-jc-center psc-bg xui-text-white psc-modal-close" xui-modal-close="viewKycModal">
						<Close width="24" height="24" />
					</div>
					{
						loadingViewKyc ?
							<center>
								<Loading width="12" height="12" />
							</center> : (
								viewKyc && viewKyc.success ?
									<>
										<center>
											<h1>Kyc Details</h1>
											<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">
												<div className='xui-d-inline-flex xui-flex-ai-center'>
													<span>#{viewKyc.data.data.unique_id}</span>
													<span title="Copy Kyc ID" className="xui-cursor-pointer xui-ml-1" onClick={() => { copyText(viewKyc.data.data.unique_id); setTextCopied(viewKyc.data.data.unique_id); }}>
														{copiedText && textCopied === viewKyc.data.data.unique_id ? <Check width="16" height="16" /> : <Copy width="16" height="16" />}
													</span>
												</div>
											</p>
										</center>
										<div className="xui-d-grid xui-grid-col-1 xui-lg-grid-col-2 xui-md-grid-col-2 xui-grid-gap-1">
											<div className="xui-w-200 xui-h-200 xui-m-1-half xui-mb-6">
												{
													viewKyc.data.user_data === null || !viewKyc.data.user_data ?
														<center>No Profile image</center> :
														<img className="xui-img-200 xui-max-h-200 xui-bdr-rad-circle" src={viewKyc.data.user_data.photo} alt={viewKyc.data.user_data.firstname + (viewKyc.data.user_data.middlename ? " " + viewKyc.data.user_data.middlename + " " : " ") + viewKyc.data.user_data.lastname + " Image"} />
												}
											</div>
											<div className="xui-m-1-half xui-lg-ml--10 xui-md-ml--7">
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">User -</span> {viewKyc.data.user_data.firstname + (viewKyc.data.user_data.middlename ? " " + viewKyc.data.user_data.middlename + " " : " ") + viewKyc.data.user_data.lastname}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Email -</span> {viewKyc.data.user_data.email}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Phone Number -</span> {viewKyc.data.user_data.phone_number ? viewKyc.data.user_data.phone_number : "No phone number"}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Gender -</span> {viewKyc.data.user_data.gender}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Date Of Birth -</span> {viewKyc.data.user_data.dob}</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">KYC Details: </p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Number -</span> {viewKyc.data.data.number}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Proof Type -</span> {viewKyc.data.data.proof_type}</p>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half"><span className="xui-font-w-bold">Verified -</span> {
													viewKyc.data.data.verified ?
														<span className='xui-badge xui-badge-success xui-font-sz-80 xui-bdr-rad-half'>Yes</span> :
														<span className='xui-badge xui-badge-danger xui-font-sz-80 xui-bdr-rad-half'>No</span>
												}</p>
												<hr></hr>
												<p className="xui-opacity-4 xui-font-sz-100 xui-m-half">Proof: </p>
												{
													viewKyc.data.data.proof ? 
														<img className="xui-img-300 xui-max-h-300 xui-mt-2" src={viewKyc.data.data.proof} alt={viewKyc.data.user_data.firstname + (viewKyc.data.user_data.middlename ? " " + viewKyc.data.user_data.middlename + " " : " ") + viewKyc.data.user_data.lastname + " Proof"} /> : 
														<span className='xui-badge xui-badge-info xui-font-sz-80 xui-bdr-rad-half'>No proof</span>
												}
												{
													viewKyc.data.data.proof ? 
														<p className="xui-opacity-4 xui-font-sz-100 xui-m-half xui-cursor-pointer xui-text-dc-underline" onClick={() => {
															showPreview(viewKyc.data.data.proof);
														}}><span className="xui-font-w-bold">Click to view</span></p> : null 
												}
											</div>
										</div>
										<center>
											<p className="xui-opacity-4 xui-font-sz-90 xui-m-half">Created - {viewKyc.data.data.createdAt.fulldate} | Last Updated - {viewKyc.data.data.updatedAt.fulldate}</p>
											<div className="xui-m-2">
												<button title="Verify User Kyc" onClick={() => { VerifyKycSetUniqueId(viewKyc.data.data.unique_id); VerifyKycSetUserUniqueId(viewKyc.data.data.user_unique_id); }} className="xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-50" xui-modal-open="verifyKycModal">
													<Check width="16" height="16" />
												</button>
												<button title="Decline User Kyc" onClick={() => { DeclineKycSetUniqueId(viewKyc.data.data.unique_id); DeclineKycSetUserUniqueId(viewKyc.data.data.user_unique_id); }} className="xui-ml-3 xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-50" xui-modal-open="declineKycModal">
													<Close width="16" height="16" />
												</button>
											</div> 
										</center>
									</> :
									<div className="xui-d-grid xui-lg-grid-col-1 xui-grid-gap-2 xui-mt-2">
										<div className="xui-bdr-w-1 xui-bdr-s-solid xui-bdr-fade xui-py-2 xui-px-1">
											<center className="xui-text-red">
												<Close width="100" height="100" />
												<h3 className="xui-font-sz-120 xui-font-w-normal xui-mt-half">{errorViewKyc}</h3>
											</center>
										</div>
									</div>
							)
					}
				</div>
			</section>
			<section className='xui-modal' xui-modal="verifyKycModal" id="verifyKycModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Verify User Kyc</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorVerifyKyc}</span></p>
					<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successVerifyKyc}</span></p>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleVerifyKyc} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingVerifyKyc ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingVerifyKyc ? "" : "verifyKycModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
			<section className='xui-modal' xui-modal="declineKycModal" id="declineKycModal">
				<div className='xui-modal-content xui-max-h-500 xui-overflow-auto xui-pos-relative'>
					<center>
						<h1>Decline User Kyc</h1>
						<p className="xui-opacity-5 xui-font-sz-90 xui-mt-half">Are you sure you want to continue with this action?</p>
					</center>
					<form className="xui-form xui-mt-2">
						<div className="xui-w-fluid-100 xui-lg-w-fluid-100">
							<label>Message</label>
							<textarea type={"text"} maxLength={500} placeholder={"Drop a feedback message"} value={message} onChange={handleMessage}></textarea>
						</div>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-red"><span className="xui-font-w-bold psc-text-red">{errorDeclineKyc}</span></p>
						<p className="xui-font-sz-100 xui-my-1 xui-mt-1 xui-text-center xui-text-green"><span className="xui-font-w-bold psc-text-red">{successDeclineKyc}</span></p>
					</form>
					<div className="xui-d-flex xui-flex-ai-center xui-flex-jc-space-evenly xui-mt-2">
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button onClick={handleDeclineKyc} className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-green xui-bdr-rad-half xui-font-sz-85">
								<span className="xui-mr-half">Yes</span>
								{
									loadingDeclineKyc ?
										<Loading width="12" height="12" />
										: <Check width="20" height="20" />
								}
							</button>
						</div>
						<div className="xui-d-inline-flex xui-flex-ai-center">
							<button className="xui-d-inline-flex xui-flex-ai-center xui-btn psc-btn-red xui-bdr-rad-half xui-font-sz-85" xui-modal-close={loadingDeclineKyc ? "" : "declineKycModal"}>
								<span className="xui-mr-half">No</span>
								<Close width="20" height="20" />
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);

};