import { useState } from "react";
import useCookie from "./useCookie";
import { config } from "../config";
import { declineKyc, verifyKyc } from "../api/kyc";

const useVerifyKyc = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingVerifyKyc, setLoadingVerifyKyc] = useState(false);
	const [removeVerifyKycModal, setRemoveVerifyKycModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [userUniqueId, setUserUniqueId] = useState(null);

	const [errorVerifyKyc, setErrorVerifyKyc] = useState(null);
	const [successVerifyKyc, setSuccessVerifyKyc] = useState(null);

	const handleVerifyKyc = (e) => {
		e.preventDefault();

		if (!loadingVerifyKyc) {
			if (!uniqueId) {
				setErrorVerifyKyc(null);
				setSuccessVerifyKyc(null);
				setErrorVerifyKyc("Unique ID is required");
				setTimeout(function () {
					setErrorVerifyKyc(null);
				}, 2500)
			} else if (!userUniqueId) {
				setErrorVerifyKyc("User Unique Id is required");
				setTimeout(function () {
					setErrorVerifyKyc(null);
				}, 2500)
			} else {
				setLoadingVerifyKyc(true);

				const verifyKycRes = verifyKyc(cookie, {
					unique_id: uniqueId,
					user_unique_id: userUniqueId
				})

				verifyKycRes.then(res => {
					setLoadingVerifyKyc(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorVerifyKyc(error);
							setTimeout(function () {
								setErrorVerifyKyc(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorVerifyKyc(error);
							setTimeout(function () {
								setErrorVerifyKyc(null);
							}, 2000)
						}
					} else {
						setErrorVerifyKyc(null);
						setSuccessVerifyKyc(`KYC verified successfully!`);

						setTimeout(function () {
							setSuccessVerifyKyc(null);
							setRemoveVerifyKycModal(true);
							setUniqueId(null);
							setUserUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingVerifyKyc(false);
				})

			}
		}
	};

	return {
		cookie, loadingVerifyKyc, removeVerifyKycModal, errorVerifyKyc, successVerifyKyc, handleVerifyKyc,
		setRemoveVerifyKycModal, setUniqueId, setUserUniqueId,
	};
};

const useDeclineKyc = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDeclineKyc, setLoadingDeclineKyc] = useState(false);
	const [removeDeclineKycModal, setRemoveDeclineKycModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [userUniqueId, setUserUniqueId] = useState(null);
	const [message, setMessage] = useState(null);

	const [errorDeclineKyc, setErrorDeclineKyc] = useState(null);
	const [successDeclineKyc, setSuccessDeclineKyc] = useState(null);

	const handleMessage = (e) => { e.preventDefault(); setMessage(e.target.value); };

	const handleDeclineKyc = (e) => {
		e.preventDefault();

		if (!loadingDeclineKyc) {
			if (!uniqueId) {
				setErrorDeclineKyc(null);
				setSuccessDeclineKyc(null);
				setErrorDeclineKyc("Unique ID is required");
				setTimeout(function () {
					setErrorDeclineKyc(null);
				}, 2500)
			} else if (!userUniqueId) {
				setErrorDeclineKyc("User Unique Id is required");
				setTimeout(function () {
					setErrorDeclineKyc(null);
				}, 2500)
			} else if (!message) {
				setErrorDeclineKyc("Message is required");
				setTimeout(function () {
					setErrorDeclineKyc(null);
				}, 2500)
			} else if (message.length < 3) {
				setErrorDeclineKyc("Message minimum characters - 3");
				setTimeout(function () {
					setErrorDeclineKyc(null);
				}, 2500)
			} else if (message.length > 500) {
				setErrorDeclineKyc("Message maximum characters - 500");
				setTimeout(function () {
					setErrorDeclineKyc(null);
				}, 2500)
			} else {
				setLoadingDeclineKyc(true);

				const declineKycRes = declineKyc(cookie, {
					unique_id: uniqueId,
					user_unique_id: userUniqueId,
					message: message
				})

				declineKycRes.then(res => {
					setLoadingDeclineKyc(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeclineKyc(error);
							setTimeout(function () {
								setErrorDeclineKyc(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeclineKyc(error);
							setTimeout(function () {
								setErrorDeclineKyc(null);
							}, 2000)
						}
					} else {
						setErrorDeclineKyc(null);
						setSuccessDeclineKyc(`KYC declined successfully!`);

						setTimeout(function () {
							setSuccessDeclineKyc(null);
							setRemoveDeclineKycModal(true);
							setUniqueId(null);
							setUserUniqueId(null);
							setMessage(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeclineKyc(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeclineKyc, removeDeclineKycModal, errorDeclineKyc, successDeclineKyc, handleDeclineKyc,
		setRemoveDeclineKycModal, setUniqueId, setUserUniqueId, setMessage, message, handleMessage
	};
};

export { useVerifyKyc, useDeclineKyc };
