import { useState } from "react";
import useCookie from "./useCookie";
import { config } from "../config";
import { publishProduct } from "../api/products";

const usePublishProduct = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingPublishProduct, setLoadingPublishProduct] = useState(false);
	const [removePublishProductModal, setRemovePublishProductModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [userUniqueId, setUserUniqueId] = useState(null);

	const [errorPublishProduct, setErrorPublishProduct] = useState(null);
	const [successPublishProduct, setSuccessPublishProduct] = useState(null);

	const handlePublishProduct = (e) => {
		e.preventDefault();

		if (!loadingPublishProduct) {
			if (!uniqueId) {
				setErrorPublishProduct(null);
				setSuccessPublishProduct(null);
				setErrorPublishProduct("Unique ID is required");
				setTimeout(function () {
					setErrorPublishProduct(null);
				}, 2500)
			} else if (!userUniqueId) {
				setErrorPublishProduct("User Unique Id is required");
				setTimeout(function () {
					setErrorPublishProduct(null);
				}, 2500)
			} else {
				setLoadingPublishProduct(true);

				const publishProductRes = publishProduct(cookie, {
					unique_id: uniqueId,
					user_unique_id: userUniqueId
				})

				publishProductRes.then(res => {
					setLoadingPublishProduct(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorPublishProduct(error);
							setTimeout(function () {
								setErrorPublishProduct(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorPublishProduct(error);
							setTimeout(function () {
								setErrorPublishProduct(null);
							}, 2000)
						}
					} else {
						setErrorPublishProduct(null);
						setSuccessPublishProduct(`Product publication toggled successfully!`);

						setTimeout(function () {
							setSuccessPublishProduct(null);
							setRemovePublishProductModal(true);
							setUniqueId(null);
							setUserUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingPublishProduct(false);
				})

			}
		}
	};

	return {
		cookie, loadingPublishProduct, removePublishProductModal, errorPublishProduct, successPublishProduct, handlePublishProduct,
		setRemovePublishProductModal, setUniqueId, setUserUniqueId, uniqueId, userUniqueId
	};
};

export { usePublishProduct };
