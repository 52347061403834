import axios from 'axios';
import { config } from '../config';

const getKycs = async function (key, page, size) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/kyc/all?page=${page}&size=${size}`,
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getKycsViaVerification = async function (key, page, size, payload) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/kyc/all/via/verification?page=${page}&size=${size}&verified=${payload.verified}`,
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getKycsViaType = async function (key, page, size, payload) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/kyc/all/via/type?page=${page}&size=${size}&proof_type=${payload.proof_type}`,
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const getUserKyc = async function (key, payload) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/kyc/via/user?user_unique_id=${payload.user_unique_id}`,
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const verifyKyc = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/kyc/verify`,
			{
				...payload
			},
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const declineKyc = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/document/user/kyc/decline`,
			{
				...payload
			},
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export { getKycs, getKycsViaVerification, getKycsViaType, getUserKyc, verifyKyc, declineKyc };