import { useState } from "react";
import useCookie from "../hooks/useCookie";
import { config } from "../config";
import { retryEmail } from "../api/mailing";

const useRetryEmail = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingRetryEmail, setLoadingRetryEmail] = useState(false);
	const [removeRetryEmailModal, setRemoveRetryEmailModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorRetryEmail, setErrorRetryEmail] = useState(null);
	const [successRetryEmail, setSuccessRetryEmail] = useState(null);

	const handleRetryEmail = () => {

		if (!loadingRetryEmail) {
			if (!uniqueId) {
				setErrorRetryEmail(null);
				setSuccessRetryEmail(null);
				setErrorRetryEmail("Unique ID is required");
				setTimeout(function () {
					setErrorRetryEmail(null);
				}, 2500)
			} else {
				setLoadingRetryEmail(true);

				const retryEmailRes = retryEmail(cookie, {
					unique_id: uniqueId
				})

				retryEmailRes.then(res => {
					setLoadingRetryEmail(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorRetryEmail(error);
							setTimeout(function () {
								setErrorRetryEmail(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorRetryEmail(error);
							setTimeout(function () {
								setErrorRetryEmail(null);
							}, 2000)
						}
					} else {
						setErrorRetryEmail(null);
						setSuccessRetryEmail(`Email retried successfully!`);

						setTimeout(function () {
							setSuccessRetryEmail(null);
							setRemoveRetryEmailModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingRetryEmail(false);
				})

			}
		}
	};

	return {
		cookie, loadingRetryEmail, removeRetryEmailModal, errorRetryEmail, successRetryEmail, handleRetryEmail,
		setRemoveRetryEmailModal, setUniqueId
	};
};

export { useRetryEmail };