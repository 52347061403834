import axios from 'axios';
import { config } from '../config';

const getFaqs = async function (key, page, size) {
	try {
		const response = await axios.get(
			`${config.baseAPIurl}/root/faq/all?page=${page}&size=${size}`,
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const addFaq = async function (key, payload) {
	try {
		const response = await axios.post(
			`${config.baseAPIurl}/root/faq/add`,
			{
				...payload
			},
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const editFaq = async function (key, payload) {
	try {
		const response = await axios.put(
			`${config.baseAPIurl}/root/faq/edit`,
			{
				...payload
			},
			{
				headers: {
					'hydra-express-access-key': key
				}
			}
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

const deleteFaq = async function (key, payload) {
	try {
		const response = await axios.delete(
			`${config.baseAPIurl}/root/faq`,
			{
				data: {
					key,
					...payload
				}
			},
		);
		return { err: false, data: response.data };
	} catch (error) {
		return { err: true, error, response_code: error.response.status };
	}
};

export { getFaqs, addFaq, editFaq, deleteFaq };