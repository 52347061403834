import { useState } from "react";
import useCookie from "./useCookie";
import { config } from "../config";
import { addFaq, deleteFaq, editFaq } from "../api/faqs";

const useAddFaq = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingAddFaq, setLoadingAddFaq] = useState(false);
	const [removeAddFaqModal, setRemoveAddFaqModal] = useState(null);
	const [question, setQuestion] = useState(null);
	const [answer, setAnswer] = useState(null);

	const [errorAddFaq, setErrorAddFaq] = useState(null);
	const [successAddFaq, setSuccessAddFaq] = useState(null);

	const handleQuestion = (e) => { e.preventDefault(); setQuestion(e.target.value); };
	const handleAnswer = (e) => { e.preventDefault(); setAnswer(e.target.value); };

	const handleAddFaq = (e) => {
		e.preventDefault();

		if (!loadingAddFaq) {
			if (!question) {
				setErrorAddFaq(null);
				setSuccessAddFaq(null);
				setErrorAddFaq("Question is required");
				setTimeout(function () {
					setErrorAddFaq(null);
				}, 2500)
			} else if (question.length > 200) {
				setErrorAddFaq("Question maximum characters - 200");
				setTimeout(function () {
					setErrorAddFaq(null);
				}, 2500)
			} else if (!answer) {
				setErrorAddFaq("Answer is required");
				setTimeout(function () {
					setErrorAddFaq(null);
				}, 2500)
			} else if (answer.length > 1000) {
				setErrorAddFaq("Answer maximum characters - 1000");
				setTimeout(function () {
					setErrorAddFaq(null);
				}, 2500)
			} else {
				setLoadingAddFaq(true);

				const addFaqRes = addFaq(cookie, {
					question,
					answer,
				})

				addFaqRes.then(res => {
					setLoadingAddFaq(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorAddFaq(error);
							setTimeout(function () {
								setErrorAddFaq(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorAddFaq(error);
							setTimeout(function () {
								setErrorAddFaq(null);
							}, 2000)
						}
					} else {
						setErrorAddFaq(null);
						setSuccessAddFaq(`Faq added successfully!`);

						setTimeout(function () {
							setSuccessAddFaq(null);
							setRemoveAddFaqModal(true);
							setQuestion(null);
							setAnswer(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingAddFaq(false);
				})

			}
		}
	};

	return {
		cookie, question, answer, loadingAddFaq, setRemoveAddFaqModal, errorAddFaq, successAddFaq, removeAddFaqModal,
		handleAddFaq, handleQuestion, handleAnswer, setQuestion, setAnswer,
	};
};

const useUpdateFaqDetails = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingUpdateFaqDetails, setLoadingUpdateFaqDetails] = useState(false);
	const [removeUpdateFaqDetailsModal, setRemoveUpdateFaqDetailsModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);
	const [question, setQuestion] = useState(null);
	const [answer, setAnswer] = useState(null);

	const [errorUpdateFaqDetails, setErrorUpdateFaqDetails] = useState(null);
	const [successUpdateFaqDetails, setSuccessUpdateFaqDetails] = useState(null);

	const handleQuestion = (e) => { e.preventDefault(); setQuestion(e.target.value); };
	const handleAnswer = (e) => { e.preventDefault(); setAnswer(e.target.value); };

	const handleUpdateFaqDetails = (e) => {
		e.preventDefault();

		if (!loadingUpdateFaqDetails) {
			if (!uniqueId) {
				setErrorUpdateFaqDetails(null);
				setSuccessUpdateFaqDetails(null);
				setErrorUpdateFaqDetails("Unique ID is required");
				setTimeout(function () {
					setErrorUpdateFaqDetails(null);
				}, 2500)
			} else if (!question) {
				setErrorUpdateFaqDetails("Question is required");
				setTimeout(function () {
					setErrorUpdateFaqDetails(null);
				}, 2500)
			} else if (question.length > 200) {
				setErrorUpdateFaqDetails("Question maximum characters - 200");
				setTimeout(function () {
					setErrorUpdateFaqDetails(null);
				}, 2500)
			} else if (!answer) {
				setErrorUpdateFaqDetails("Answer is required");
				setTimeout(function () {
					setErrorUpdateFaqDetails(null);
				}, 2500)
			} else if (answer.length > 1000) {
				setErrorUpdateFaqDetails("Answer maximum characters - 1000");
				setTimeout(function () {
					setErrorUpdateFaqDetails(null);
				}, 2500)
			} else {
				setLoadingUpdateFaqDetails(true);

				const editFaqRes = editFaq(cookie, {
					unique_id: uniqueId,
					question,
					answer
				})

				editFaqRes.then(res => {
					setLoadingUpdateFaqDetails(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorUpdateFaqDetails(error);
							setTimeout(function () {
								setErrorUpdateFaqDetails(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorUpdateFaqDetails(error);
							setTimeout(function () {
								setErrorUpdateFaqDetails(null);
							}, 2000)
						}
					} else {
						setErrorUpdateFaqDetails(null);
						setSuccessUpdateFaqDetails(`Faq details updated!`);

						setTimeout(function () {
							setSuccessUpdateFaqDetails(null);
							setRemoveUpdateFaqDetailsModal(true);
							setUniqueId(null);
							setQuestion(null);
							setAnswer(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingUpdateFaqDetails(false);
				})

			}
		}
	};

	return {
		cookie, loadingUpdateFaqDetails, removeUpdateFaqDetailsModal, errorUpdateFaqDetails, successUpdateFaqDetails, handleUpdateFaqDetails,
		setRemoveUpdateFaqDetailsModal, setUniqueId, setQuestion, setAnswer, question, answer, handleQuestion, handleAnswer
	};
};

const useDeleteFaq = () => {

	const { cookie } = useCookie(config.key, "");

	const [loadingDeleteFaq, setLoadingDeleteFaq] = useState(false);
	const [removeDeleteFaqModal, setRemoveDeleteFaqModal] = useState(null);
	const [uniqueId, setUniqueId] = useState(null);

	const [errorDeleteFaq, setErrorDeleteFaq] = useState(null);
	const [successDeleteFaq, setSuccessDeleteFaq] = useState(null);

	const handleDeleteFaq = () => {

		if (!loadingDeleteFaq) {
			if (!uniqueId) {
				setErrorDeleteFaq(null);
				setSuccessDeleteFaq(null);
				setErrorDeleteFaq("Unique ID is required");
				setTimeout(function () {
					setErrorDeleteFaq(null);
				}, 2500)
			} else {
				setLoadingDeleteFaq(true);

				const deleteFaqRes = deleteFaq(cookie, {
					unique_id: uniqueId
				})

				deleteFaqRes.then(res => {
					setLoadingDeleteFaq(false);
					if (res.err) {
						if (!res.error.response.data.success) {
							const error = `${res.error.response.status !== 422 ? res.error.response.data.message : res.error.response.data.data[0].msg}`;
							setErrorDeleteFaq(error);
							setTimeout(function () {
								setErrorDeleteFaq(null);
							}, 2000)
						} else {
							const error = `${res.error.code} - ${res.error.message}`;
							setErrorDeleteFaq(error);
							setTimeout(function () {
								setErrorDeleteFaq(null);
							}, 2000)
						}
					} else {
						setErrorDeleteFaq(null);
						setSuccessDeleteFaq(`Faq deleted successfully!`);

						setTimeout(function () {
							setSuccessDeleteFaq(null);
							setRemoveDeleteFaqModal(true);
							setUniqueId(null);
						}, 2500)
					}
				}).catch(err => {
					setLoadingDeleteFaq(false);
				})

			}
		}
	};

	return {
		cookie, loadingDeleteFaq, removeDeleteFaqModal, errorDeleteFaq, successDeleteFaq, handleDeleteFaq,
		setRemoveDeleteFaqModal, setUniqueId
	};
};

export { useAddFaq, useUpdateFaqDetails, useDeleteFaq };
